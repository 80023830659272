<template>
  <!-- 学徒管理 -->
  <div class="container">
    <a-page-header :title="title" />

    <div class="main-content">
      <div class="ribbon-wrap">
        <div class="item">
          <div class="search-box">
            <span class="label">模糊搜索：</span>
            <a-input
              class="search-input"
              v-model="searchValue"
              placeholder="尝试搜索姓名、手机号等"
            />
          </div>
        </div>
        <div class="item">
          <div class="btn-box" v-permission="'search'">
            <a-button @click="searchReset">
              重置
            </a-button>
            <a-button type="primary" @click="searchSubmit">
              查询
            </a-button>
          </div>
        </div>
      </div>

      <div class="main-content-header">
        <div class="item">
          <div class="title">实习学徒列表</div>
        </div>
        <div class="item">
          <a-button
            v-permission="'add'"
            type="primary"
            icon="plus"
            @click="$jump({ path: '/apprenticeAdd' })"
          >
            录入学徒
          </a-button>
          <a-button v-permission="'export'">
            导出学徒花名册
          </a-button>
        </div>
      </div>

      <my-table
        :columns="columns"
        :data-source="dataSource"
        :pagination="pagination"
        :loading="loading"
        :scroll="{ x: 1070, y: true }"
        @change="handlePaginationChange"
      >
        <template slot="apply" slot-scope="record">
          <a-badge
            :status="applyText[record.apply].status"
            :text="applyText[record.apply].text"
          />
        </template>
        <template slot="train" slot-scope="record">
          <a-badge
            :status="trainText[record.train].status"
            :text="trainText[record.train].text"
          />
        </template>
        <template slot="action" slot-scope="record">
          <a-button type="link">
            <router-link
              v-permission="'info'"
              :to="{
                path: '/apprenticeInfo',
                query: { id: record.id }
              }"
            >
              更多详情
            </router-link>
          </a-button>
          <a-button type="link">
            <router-link
              :to="{
                path: '/apprenticeTraining',
                query: { id: record.id }
              }"
            >
              培训管理
            </router-link>
          </a-button>
          <a-dropdown placement="bottomCenter">
            <a-icon type="ellipsis" />
            <!-- <a-menu slot="overlay">
              <a-menu-item
                :key="0"
                @click="
                  $jump({
                    path: '/apprenticeAssessment',
                    query: {
                      id: record.id,
                      name: `${record.realName}（${record.studentNumber}）`
                    }
                  })
                "
              >
                考核转正
              </a-menu-item>
              <a-menu-item
                :key="1"
                @click="setApprenticeStatus(record.id, record.status)"
              >
                <span v-if="record.status === 1">培训完成</span>
                <span v-else>培训中</span>
              </a-menu-item>
            </a-menu> -->
          </a-dropdown>
        </template>
      </my-table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      title: '',
      searchValue: '',
      applyText: [
        {
          status: 'default',
          text: '未转编'
        },
        {
          status: 'success',
          text: '转编师傅'
        }
      ],
      trainText: [
        {
          status: 'success',
          text: '培训中'
        },
        {
          status: 'default',
          text: '培训完成'
        },
        {
          status: 'default',
          text: '培训中止'
        }
      ],
      dataSource: [],
      columns: [
        {
          title: '序号',
          width: 70,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '学徒姓名',
          width: 150,
          dataIndex: 'realName'
        },
        {
          title: '学徒联系方式',
          width: 150,
          dataIndex: 'mobile'
        },
        {
          title: '学号',
          width: 150,
          dataIndex: 'studentNumber'
        },
        {
          title: '学徒状态',
          width: 150,
          scopedSlots: { customRender: 'train' }
        },
        {
          title: '转编状态',
          scopedSlots: { customRender: 'apply' }
        },
        {
          title: '操作',
          width: 250,
          fixed: 'right',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      loading: true,
      pagination: {
        current: 1,
        total: 0
      }
    }
  },
  computed: {
    ...mapState(['refreshApprentice'])
  },
  created() {
    this.title = this.$getPageTitle()
    this.getApprentice()
  },
  methods: {
    searchReset() {
      // 重置搜索
      this.searchValue = ''
      this.getApprentice()
    },
    searchSubmit() {
      // 搜索
      if (!this.searchValue) return
      this.pagination.current = 1
      this.loading = true
      this.getApprentice()
    },
    handlePaginationChange(e) {
      this.loading = true
      this.pagination.current = e.current
      this.getApprentice()
    },
    getApprentice() {
      // 获取学徒列表
      const data = {
        page: this.pagination.current,
        limit: 10
      }
      if (this.searchValue) {
        this.loading = true
        data.keyword = this.searchValue
        data.page = 1
        this.pagination.current = 1
      }
      this.$axios.getApprentice(data).then((res) => {
        this.dataSource = res.data.data.list
        this.pagination.total = res.data.data.count
        this.loading = false
      })
    },
    setApprenticeStatus(id, status) {
      // 设置学徒状态
      let title = ''
      if (status === 1) {
        title = '确定设为培训完成？'
        status = 0
      } else {
        title = '确定设为培训中？'
        status = 1
      }
      this.$confirm({
        title: title,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          const data = {
            id,
            status
          }
          this.$axios.setApprenticeStatus(data).then(() => {
            this.$message.success('操作成功')
            this.getApprentice()
          })
        }
      })
    }
  },
  watch: {
    refreshApprentice() {
      // 刷新
      this.loading = true
      this.pagination.current = 1
      this.getApprentice()
    }
  }
}
</script>

<style scoped></style>
